import { computed } from "vue";
import router from "@/router";
import IChatParams from "@/types/IChatParams";
import { useChatStore } from "@/store/chat";
import { useCoachStore } from "@/store/coach";
import { useUserStore } from "@/store/user";
import IMessage from "@/types/IMessage";

export function useSupportChat(socketChat?: any) {
  const chatStore = useChatStore();
  const coachStore = useCoachStore();
  const userStore = useUserStore();

  const activeChatId = computed(
    (): string | undefined =>
      (router.currentRoute.value.params?.id as string) || undefined
  );

  const activeChatData = computed(() =>
    chatStore.data.find((chat) => chat._id === activeChatId.value)
  );

  const isChatJoined = computed(
    (): boolean =>
      !!chatStore.data.find(
        (item) => item._id === (activeChatId.value as string)
      )?.isJoined
  );

  const isCoach = computed(
    (): boolean =>
      !!(
        coachStore &&
        Object.keys(coachStore.data).length &&
        coachStore.coachAccessToken
      )
  );

  const currentUserName = computed((): string =>
    isCoach.value
      ? (coachStore?.data?.fullName?.trim() as string)
      : (userStore?.data.fullName?.trim() as string)
  );

  const isSignedAtTermsOfUse = computed((): boolean =>
    isCoach.value ? true : !!userStore.data.signedAt
  );

  const emitCallbackFn = (res: any, resolve: any, reject: any) => {
    if (res.error) {
      console.log("Error: ", res.error);
      reject(res.error);
    } else {
      resolve(res.data);
    }
  };

  const getChats = (
    payload: IChatParams = {
      type: "workout",
      page: 0,
    }
  ) => {
    return new Promise((resolve, reject) => {
      const waitSocketChatConnect = setInterval(() => {
        if (socketChat && socketChat.connected) {
          socketChat.emit("get-chats", payload, (res: any) => {
            emitCallbackFn(res, resolve, reject);
          });
          clearInterval(waitSocketChatConnect);
        }
      }, 100);
    });
  };

  const getChatById = (chatId: string) => {
    return new Promise((resolve, reject) => {
      socketChat.emit("get-chat-by-id", { chatId }, (res: any) => {
        emitCallbackFn(res, resolve, reject);
      });
    });
  };

  const loadMessages = (payload: IChatParams) => {
    return new Promise((resolve, reject) => {
      const waitSocketChatConnect = setInterval(() => {
        if (socketChat && socketChat.connected) {
          socketChat.emit("get-messages", payload, (res: IMessage[]) => {
            emitCallbackFn(res, resolve, reject);
          });
          clearInterval(waitSocketChatConnect);
        }
      }, 100);
    });
  };

  const writeMessage = (payload: {
    chatId: string;
    message?: string;
    attachments?: string[];
  }) => {
    return new Promise((resolve, reject) => {
      socketChat.emit("write-message", payload, (res: any) => {
        emitCallbackFn(res, resolve, reject);
      });
    });
  };

  const readMessage = (chatId: string, messageId: string) => {
    return new Promise((resolve, reject) => {
      socketChat.emit("read-message", { chatId, messageId }, (res: any) => {
        emitCallbackFn(res, resolve, reject);
      });
    });
  };

  const editMessage = (
    chatId: string,
    messageId: string,
    newMessage: string
  ) => {
    return new Promise((resolve, reject) => {
      socketChat.emit(
        "edit-message",
        { chatId, messageId, newMessage },
        (res: any) => {
          emitCallbackFn(res, resolve, reject);
        }
      );
    });
  };

  const deleteMessage = (chatId: string, messageId: string) => {
    return new Promise((resolve, reject) => {
      socketChat.emit("delete-message", { chatId, messageId }, (res: any) => {
        emitCallbackFn(res, resolve, reject);
      });
    });
  };

  const joinChat = (chatId: string) => {
    return new Promise((resolve, reject) => {
      socketChat.emit("join-chat", { chatId }, (res: any) => {
        emitCallbackFn(res, resolve, reject);
      });
    });
  };

  const leaveChat = (chatId: string) => {
    return new Promise((resolve, reject) => {
      socketChat.emit("leave-chat", { chatId }, (res: any) => {
        emitCallbackFn(res, resolve, reject);
      });
    });
  };

  const endChat = () => {
    return new Promise((resolve, reject) => {
      socketChat.emit(
        "end-chat",
        {
          chatId: activeChatId.value as string,
        },
        (res: any) => {
          emitCallbackFn(res, resolve, reject);
        }
      );
    });
  };

  const getChatUnreadCount = () => {
    return new Promise((resolve, reject) => {
      socketChat.emit("get-chat-unread-count", {}, (res: any) => {
        emitCallbackFn(res, resolve, reject);
      });
    });
  };

  const updateJoinStatusByChatId = (chatId: string): void => {
    const idx = chatStore.data.findIndex((chat) => chat._id === chatId);
    if (idx >= 0) {
      chatStore.data[idx].isJoined = !chatStore.data[idx].isJoined;
    }
  };

  const updateChatStatusByChatId = (chatId: string, status: string): void => {
    const idx = chatStore.data.findIndex((chat) => chat._id === chatId);
    if (idx >= 0) {
      chatStore.data[idx].status = status;
    }
  };

  return {
    activeChatId,
    activeChatData,
    isChatJoined,
    isCoach,
    isSignedAtTermsOfUse,
    currentUserName,
    getChats,
    getChatById,
    loadMessages,
    writeMessage,
    readMessage,
    editMessage,
    deleteMessage,
    joinChat,
    leaveChat,
    endChat,
    getChatUnreadCount,
    updateJoinStatusByChatId,
    updateChatStatusByChatId,
  };
}
